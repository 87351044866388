var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section data-trackable=\"concept\"\n	aria-label=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n	"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"responsiveGrids") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":1},"end":{"line":4,"column":72}}})) != null ? stack1 : "")
    + "\n	data-concept-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n	class=\"\n		topic-card__myft\n		"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":8,"column":114}}})) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"lazyLoadImages") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":9,"column":63}}})) != null ? stack1 : "")
    + "\n		"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"classes") : depth0), depth0))
    + "\"\n	"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prioritised") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":1},"end":{"line":11,"column":43}}})) != null ? stack1 : "")
    + ">\n		<header class=\"topic-card__myft-header\">\n			<p class=\"topic-card__myft-title\">\n				<a class=\"topic-card__concept-link\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" data-trackable=\"concept-link\" aria-label=\"Go to list of all articles about "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prefLabel") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":14,"column":130},"end":{"line":14,"column":183}}})) != null ? stack1 : "")
    + "\">\n					"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prefLabel") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":5},"end":{"line":15,"column":58}}})) != null ? stack1 : "")
    + "\n				</a>\n			</p>\n\n"
    + ((stack1 = __default(require("../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifAll.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"flags") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"flags") : depth0)) != null ? lookupProperty(stack1,"myFtApiWrite") : stack1),{"name":"ifAll","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":3},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + "		</header>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(20, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":25,"column":2},"end":{"line":50,"column":9}}})) != null ? stack1 : "")
    + "\n		<footer class=\"topic-card__myft-footer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"excessItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":3},"end":{"line":59,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":3},"end":{"line":62,"column":14}}})) != null ? stack1 : "")
    + "			<div class=\"topic-card__myft-btn topic-card__myft-btn--alerts\">\n"
    + ((stack1 = container.invokePartial(require("../../n-myft-ui/components/instant-alert/instant-alert.html"),depth0,{"name":"n-myft-ui/components/instant-alert/instant-alert","hash":{"name":(depth0 != null ? lookupProperty(depth0,"name") : depth0),"conceptId":(depth0 != null ? lookupProperty(depth0,"id") : depth0)},"data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "			</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"isFollowing") : depths[1]),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":3},"end":{"line":68,"column":10}}})) != null ? stack1 : "")
    + "		</footer>\n	</section>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-o-grid-colspan=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"responsiveGrids") : depth0), depth0))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "topic-card--empty "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"flags") : depth0)) != null ? lookupProperty(stack1,"myftGroupEmptyTopics") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":103}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "myft-grouped-topic";
},"7":function(container,depth0,helpers,partials,data) {
    return " topic-card--lazy-loading-images";
},"9":function(container,depth0,helpers,partials,data) {
    return "data-prioritised";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"prefLabel") : depth0), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"topic-card__myft-meta\">\n				<div class=\"topic-card__myft-btn topic-card__myft-btn--following\">"
    + ((stack1 = container.invokePartial(require("../../n-myft-ui/components/follow-button/follow-button.html"),depth0,{"name":"n-myft-ui/components/follow-button/follow-button","hash":{"extraClasses":"topic-card__concept-follow","name":(depth0 != null ? lookupProperty(depth0,"name") : depth0),"conceptId":(depth0 != null ? lookupProperty(depth0,"id") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n			</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"topic-card__myft-content\">\n				<ul class=\"topic-card__concept-articles\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"items") : depth0), depth0),{"name":"items","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":5},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "				</ul>\n			</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./concept-article.html"),depth0,{"name":"n-topic-card/templates/concept-article","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"topic-card__myft-content\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"flags") : depth0)) != null ? lookupProperty(stack1,"myftGroupEmptyTopics") : stack1),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "			</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<p class=\"topic-card__concept-empty\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"nTopicCardEmptyMessage") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":41,"column":13}}})) != null ? stack1 : "")
    + "					</p>\n					<p class=\"topic-card__concept-empty\">\n						<a class=\"topic-card__concept-empty-link\" data-trackable=\"empty-link\" href="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + ">\n							See more on this topic\n						</a>\n					</p>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"nTopicCardEmptyMessage") : depth0), depth0))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "							No new articles on this topic\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<p class=\"topic-card__excess\">\n				<a class=\"topic-card__excess-link\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" data-trackable=\"excess-stories-link\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"excessItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " more stor"
    + ((stack1 = __default(require("../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifEquals.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"excessItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),1,{"name":"ifEquals","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":55,"column":137},"end":{"line":55,"column":197}}})) != null ? stack1 : "")
    + "\">\n					"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"excessItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " more stor"
    + ((stack1 = __default(require("../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifEquals.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"excessItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),1,{"name":"ifEquals","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":56,"column":39},"end":{"line":56,"column":99}}})) != null ? stack1 : "")
    + "\n				</a>\n			</p>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "y";
},"29":function(container,depth0,helpers,partials,data) {
    return "ies";
},"31":function(container,depth0,helpers,partials,data) {
    return "			<p class=\"topic-card__excess\">0 stories</p>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../n-myft-ui/components/pin-button/pin-button.html"),depth0,{"name":"n-myft-ui/components/pin-button/pin-button","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = __default(require("../handlebars-helpers/concept.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"concept","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":71,"column":12}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});