var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "topic-card__concept-article--read";
},"3":function(container,depth0,helpers,partials,data) {
    return " topic-card__concept-article--video";
},"5":function(container,depth0,helpers,partials,data) {
    return " topic-card__concept-article--audio";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "?kbc="
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"conceptId") : depths[1]), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    return "			<span class=\"topic-card__classifier\">Premium</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"topic-card__concept-article "
    + ((stack1 = __default(require("../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifEquals.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBeenRead") : depth0),true,{"name":"ifEquals","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":115}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifEquals.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"video",{"name":"ifEquals","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":115},"end":{"line":1,"column":189}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifEquals.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"audio",{"name":"ifEquals","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":189},"end":{"line":1,"column":263}}})) != null ? stack1 : "")
    + "\">\n	<a\n		class=\"topic-card__concept-article-link\"\n		href=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"conceptId") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":63}}})) != null ? stack1 : "")
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"referrerTracking") : depths[1]), depth0))
    + "\"\n		data-trackable=\"article\"\n	>\n		<span class=\"topic-card__classifier-gap\">\n			<span class=\"topic-card__concept-article-text\">\n				"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n			</span>\n		</span>\n\n		<span class=\"topic-card__concept-article-date\">\n			<time\n				data-o-component=\"o-date\"\n				class=\"o-date\"\n				data-o-date-format=\"time-ago-abbreviated\"\n				datetime=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"publishedDate") : depth0), depth0))
    + "\">\n			</time>\n		</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPremium") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":24,"column":9}}})) != null ? stack1 : "")
    + "	</a>\n\n</li>\n";
},"useData":true,"useDepths":true});